<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col cols="12" md="9" class="pb-0 pt-0">
          <PageHeader :title="title" />
        </v-col>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-col
              cols="12"
              md="3"
              class="pb-0 pt-0  text-right"
              v-on="on"
              v-bind="attrs"
            >
              <v-switch
                class="pt-0"
                v-model="deshabilitado"
                label="Deshabilitar ayuda"
                dense
                outlined
              ></v-switch>
            </v-col>
          </template>
          <span>Oculta esta ayuda al usuario.</span>
        </v-tooltip>
      </v-row>

      <v-card
        class="mt-0"
        flat
        :disabled="ayudaTab == 'sistema' && isAdministrador"
      >
        <text-editor
          ref="text"
          :value.sync="content"
          :changed.sync="contentChanged"
          :editorCfgProp="editorCfgProp"
        ></text-editor>

        <ConfirmDialog
          :text="textConfirmDialog"
          :openConfirm.sync="openConfirmDialog"
          @onConfirm="closeModal"
        />
      </v-card>

      <v-row>
        <v-spacer></v-spacer>

        <v-col cols="12" md="4" class="text-right py-1">
          <v-btn outlined class="m-2" @click="confirmCancel">
            Cancelar
          </v-btn>
          <v-btn @click="saveTextoAyuda" class="m-2" color="primary">
            Guardar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import TextEditor from "@/components/textEditor/TextEditor.vue";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import enums from "@/utils/enums/index.js";

export default {
  components: {
    TextEditor,
    PageHeader,
    ConfirmDialog
  },
  name: "TextoAyuda",
  props: {
    ayudaTab: { type: String, required: false, default: null },
    contentProp: { type: String, required: false, default: null },
    optionCode: { type: String, required: false, default: null },
    ayudaObject: { type: Object, required: false, default: null },
    isAdministrador: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      content: "",
      deshabilitado: false,
      contentChanged: false,
      title: enums.titles.TEXTO_AYUDA,
      openConfirmDialog: false,
      textConfirmDialog:
        "Al salir, los cambios sin guardar serán eliminados. ¿Desea continuar?",

      editorCfgProp: {
        init: {
          language: "es",
          height: "50vh",
          branding: false,
          menubar: false,
          setup: this.setup,
          contextmenu: "fontsizes | bold italic underline  link",
          paste_data_images: false,
          paste_as_text: true,
          paste_enable_default_filters: false
        },
        link_context_toolbar: true,
        plugins: [
          "paste",
          "autolink",
          "link",
          "lists",
          "preview",
          "autoresize",
          "anchor",
          "searchreplace",
          "visualblocks",
          "insertdatetime",
          "table",
          "wordcount"
        ],
        toolbar: `undo redo | fontsizeselect | formatselect | bold italic underline | link |
        alignleft aligncenter alignright alignjustify | 
        bullist numlist | preview `,
        fontsize_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
        value: null,
        elementpath: false
      }
    };
  },
  created() {
    if (this.ayudaObject != null) {
      this.setTextoAyuda();
    }
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      saveAyuda: "AdministracionSistema/saveAyuda"
    }),
    setTextoAyuda() {
      this.content = this.ayudaObject.texto;
      this.editorCfgProp.value = this.ayudaObject.texto;
      this.deshabilitado = this.ayudaObject.deshabilitado;
    },
    async saveTextoAyuda() {
      const data = {
        id: this.ayudaObject != null ? this.ayudaObject.id : 0,
        texto: this.content,
        optionCode: this.optionCode,
        deshabilitado: this.deshabilitado,
        esAyudaSistema: this.ayudaTab === "sistema" ? true : false
      };

      const response = await this.saveAyuda(data);
      if (response.status === 200)
        this.setAlert({ type: "success", message: "Guardado con éxito" });
      this.closeModal();
      this.$emit("getAyuda");
    },
    confirmCancel() {
      this.openConfirmDialog = true;
    },

    closeModal() {
      this.$emit("toggleModalTextoAyuda");
    }
  }
};
</script>

<style>
/* .gecrosColorClass {
  color: #195472 !important;
  font-size: 3rem;
  background-color: #195472;
} */
</style>
